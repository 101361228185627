import React from "react"
import SEO from "../components/seo"
import Helmet from 'react-helmet'
import Gallery from '../components/gallery'

const IndexPage = () => (
    <React.Fragment>
        <SEO title="Lukekalender" />
        <Helmet
            bodyAttributes={{
                class: 'page-lukekalender'
            }}
        />
        <a href="#main" title="Snarvei til hovedinnholdet" className="skip-navigation">Til innholdet</a>
        <div className="navigation">
            <div className="navigation__wrapper">
                <div className="navigation--logotype">
                    <h1>
                        <a href="/">lukekalender.no</a>
                    </h1>
                </div>
                <div className="navigation--nav">
                    <nav id="navbar">
                        <ul>
                            <li><a href="#bestill" className="order-button">Bestilling</a></li>
                            <li><a href="#funksjoner">Funksjoner</a></li>
                            <li><a href="#design">Design</a></li>
                            {/* <li><a href="/hjelp">Hjelp</a></li> */}
                        </ul>
                    </nav>
                </div>
            </div>
        </div>

        <div className="hero-container hero-container--christmas">
            <div className="container">
                <div className="hero-container__row">
                    <div className="col wow fadeIn">
                        <h2 className="hero-container__title">Nå dine kunder</h2>
                        <p className="lead hero-container__subtitle">der de er, hver dag, hele advent.</p>
                        <p className="hero-container__desc">En digital adventskalender gir deg en unik mulighet til å nå kundene dine hver eneste dag. Kalenderen er lett å spre til potensielle kunder, og den er effektiv for å øke antall abonnenter på din nyhetsbrevliste. <a href="#oversikt" className="hero-container__link">Hvordan fungerer det?</a></p>
                        <div className="hero-container__buttons">
                        <a href="#bestill" className="hero-container__button">Bestill nå</a>
                        </div>
                    </div>
                    <div className="col col--image wow fadeInUp">
                        <img src="/assets/img/christmas/kalender-mobil-white.png" className="hero-container__device" alt="En telefon med en digital adventskalender" />
                    </div>
                </div>
            </div>
        </div>

        <main className="main" id="main">
            <section className="section tutorial" id="oversikt">
                <div className="container">
                    <div className="row text-center wow fadeIn">
                        <div className="tutorial__item col-md-4">
                            <p className="h1">1.</p>
                            <h3 className="tutorial__header">Opprett kalender</h3>
                            <p className="tutorial__desc">Med tilgang til din egen administratorside har du full oversikt. Opprett spørsmål og svaralternativer for hver dag i tillegg til premier og bilder.</p>
                        </div>
                        <div className="tutorial__item col-md-4">
                            <p className="h1">2.</p>
                            <h3 className="tutorial__header">Skap engasjement</h3>
                            <p className="tutorial__desc">Del din kalender i sosiale medier, nyhetsbrev og egne kanaler. Hver dag i hele adventstiden skaper du økt kjennskap til din bedrift, produkter og tjenester.</p>
                        </div>
                        <div className="tutorial__item col-md-4">
                            <p className="h1">3.</p>
                            <h3 className="tutorial__header">Oppnå resultater</h3>
                            <p className="tutorial__desc">Dagens vinner trekkes automatisk. Du har full kontroll på daglige deltakere og får samlet nye e-postadresser til ditt nyhetsbrev. Statistikk for kampanjen følges underveis og oppsummeres til slutt.</p>
                        </div>
                    </div>
                </div>
            </section>



            <div className="section__wrapper">
                <section id="fordeler" className="section section__tight  section__full wow fadeIn">
                    <div className="badge-container">
                        <div className="badge wow fadeIn badge__section">
                            <div className="badge__inner">
                                <div className="badge__inner__wrap">
                                    {/* <!-- <div className="badge__title">
                                        30%
                                    </div>
                                    <span className="badge__subtitle">RABATT FØR <br>17. NOVEMBER</span> --> */}
                                    <span className="badge__subtitle">KUN KR.</span>
                                    <div className="badge__title">10 500<small>,-</small></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- <h2 className="uppercase ">Førjuls&#173;rabatt!</h2> --> */}
                    {/* <!--<p>Vi belønner deg som som er ute i god
            tid ved å gi deg <strong className="uppercase">30% Rabatt</strong></p>--> */}
                    <div className="price">
                        {/* <!-- <p>Nå fra kun <span className="price__price">10.500,-</span> <small>eks. mva</small>, <br>om du bestiller før 17. november!</p> --> */}
                        <p>Nå fra kun <span className="price__price">10 500,-</span> <small>eks. mva.</small> <br/>Det anbefales å bestille innen fredag 27. november!</p>
                    </div>
                    <a href="#bestill" className="call-to-action call-to-action__pull-up text-center wow bounceIn">Bestill nå</a>
                </section>

                <section id="tilgjengelig" className="section section__tight section__white section--change-order wow fadeIn">
                    <div className="equal-height">
                        <div className="equal-height__column">
                            <div className="equal-height__column__content equal-height__column--slider">
                                <h2 className="uppercase">Alltid tilgjengelig</h2>
                                <p>Førjulstiden er travel, og dine kunder er ofte på farten. Derfor er det viktig at kalenderen fungerer minst like bra på mobil.</p>
                                <ul className="list list--horizontal">
                                    <li className="list__item--horizontal"><i className="fa fa-check list__item__icon"></i> Responsivt</li>
                                    <li className="list__item--horizontal"><i className="fa fa-check list__item__icon"></i> Synlig</li>
                                    <li className="list__item--horizontal"><i className="fa fa-check list__item__icon"></i> Brukervennlig</li>
                                </ul>
                            </div>
                        </div>
                        <div className="equal-height__column">
                            <img src="/assets/img/christmas/kalender-enheter-2.jpg" className="img-responsive" alt="Digital julekalender på alle enheter: desktop, laptop, tablet og mobil"/>
                        </div>
                    </div>
                </section>

                <section id="design" className="section section__tight section__primary wow fadeIn">
                    <div className="equal-height">
                        <div className="equal-height__column equal-height__column--no-padding">
                            <Gallery
                                images={[
                                    '/assets/img/christmas/calendars/calendar-template-1.png',
                                    '/assets/img/christmas/calendars/calendar-template-2.png',
                                    '/assets/img/christmas/calendars/calendar-template-3.png',
                                    '/assets/img/christmas/calendars/calendar-template-4.png',
                                    '/assets/img/christmas/calendars/calendar-template-5.png',
                                    '/assets/img/christmas/calendars/calendar-template-6.png',
                                    '/assets/img/christmas/calendars/calendar-template-7.png',
                                    '/assets/img/christmas/calendars/calendar-template-8.png',
                                    '/assets/img/christmas/calendars/calendar-template-9.png',
                                    '/assets/img/christmas/calendars/calendar-template-10.png',
                                    '/assets/img/christmas/calendars/calendar-template-11.png'
                                ]}
                                altText='Kalendermal'
                                keyId='Kalendermal'
                                interval={3000}
                            />
                        </div>
                        <div className="equal-height__column">
                            <div className="equal-height__column__content">
                                <h2 className="uppercase">Du velger designet</h2>
                                <p>Velg mellom mange ferdige design, eller last opp ditt unike.</p>
                                {/*<p>Se eksempler i slideren.</p>*/}
                            </div>
                        </div>
                    </div>
                </section>

                <section id="funksjoner" className="section section__tight section--change-order section__white slider wow fadeIn">
                    <div className="equal-height">
                        <div className="equal-height__column ">
                            <div className="equal-height__column__content">
                                <h2 className="uppercase">Kalender&#173;funksjoner</h2>
                                <ul className="list list--two-columns ">
                                    <li className="list__item"><i className="fa fa-check list__item__icon"></i> 24 luker</li>
                                    <li className="list__item"><i className="fa fa-check list__item__icon"></i> Luke/premiebilde</li>
                                    <li className="list__item"><i className="fa fa-check list__item__icon"></i> Sponsorbilde</li>
                                    <li className="list__item"><i className="fa fa-check list__item__icon"></i> URL til landingsside per luke</li>
                                    <li className="list__item"><i className="fa fa-check list__item__icon"></i> Automatisk trekning av vinnere</li>
                                    <li className="list__item"><i className="fa fa-check list__item__icon"></i> Videoluke</li>
                                    <li className="list__item"><i className="fa fa-check list__item__icon"></i> Designgalleri</li>
                                    <li className="list__item"><i className="fa fa-check list__item__icon"></i> Tilpasset design</li>
                                    <li className="list__item"><i className="fa fa-check list__item__icon"></i> Deling på facebook</li>
                                    <li className="list__item"><i className="fa fa-check list__item__icon"></i> Egen logo</li>
                                    <li className="list__item"><i className="fa fa-check list__item__icon"></i> Statistikk</li>
                                </ul>
                            </div>
                        </div>
                        {/* <!--<div className="equal-height__column snow" style="background-image: url('assets/img/photo-1447877085163-3cce903855cd.jpeg'); background-position: 50%;"></div>--> */}
                        <div className="equal-height__column equal-height__column--no-padding">
                            <Gallery
                                images={[
                                    '/assets/img/christmas/funksjonalitet/slider-functionality-0.png',
                                    '/assets/img/christmas/funksjonalitet/slider-functionality-1.png',
                                    '/assets/img/christmas/funksjonalitet/slider-functionality-2.png',
                                    '/assets/img/christmas/funksjonalitet/slider-functionality-3.png',
                                    '/assets/img/christmas/funksjonalitet/slider-functionality-4.png',
                                    '/assets/img/christmas/funksjonalitet/slider-functionality-5.png',
                                    '/assets/img/christmas/funksjonalitet/slider-functionality-6.png'
                                ]}
                                altText="Lukekalender demo"
                                keyId="kalender-demo"
                                interval={3000}
                                autoplay={false}
                            />
                        </div>
                    </div>
                </section>
            </div>

            <section id="bestill" className="section section__form section__form-overflow">
                <div className="js-form-container form-container is-visible">
                    <h2 className="text-center uppercase">Bestill nå!</h2>
                    <p className="order lead text-center">
                        {/* <!-- Send bestilling i dag, og du vil motta den hjelpen du trenger for å komme igang med din julekalender. --> */}
                        Bestill demo i dag, og vi vil kontakte deg for en demonstrasjon av vår adventskalender.
                    </p>

                    <form name="contact" method="POST" data-netlify="true" autoComplete="on" className="request-form" action="/ferdig">
                        <input type="hidden" name="form-name" value="contact" />
                        <label htmlFor="name" className="sr-only">Fornavn / Etternavn</label>
                        <input type="text" className="input__block input-lg form-control" id="name" name="name" placeholder="Navn (påkrevd)" autoComplete="name" title="Fornavn og etternavn, minst 4 bokstaver" required/>

                        <label htmlFor="email" className="sr-only">E-post</label>
                        <input type="email" className="input__block input-lg form-control" id="email" name="email" placeholder="E-post (påkrevd)" autoComplete="email" title="epost@adresse.no" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$" required/>

                        <label htmlFor="telephone" className="sr-only">Telefon</label>
                        <input type="tel" className="input__block input-lg form-control" id="phone" name="phone" placeholder="Telefon (påkrevd)" autoComplete="tel" title="8 siffer" pattern="^((0047)?|(\+47)?|(47)?)\d{8}$" required/>

                        <textarea className="input__block form-control input-lg" id="comment" name="comment" placeholder="Kommentar til bestillingen" rows="5" title="Maks 300 tegn" maxLength="300"></textarea>

                        <button type="submit" className="input-lg submit-button wow bounceIn js-form-submit" data-loading="Sender bestilling...">Send bestilling</button>
                    </form>
                </div>

                <div className="js-form-feedback form-feedback text-center" aria-live="assertive" role="alert">
                    <div className="content"></div>
                </div>
            </section>
        </main>

        <footer className="footer">
            <div className="container">
                <div className="footer__grid">
                    <div className="footer__grid__column">
                        <p>
                        <a href="tel:004733426600">+47 33 42 66 00</a><br/>
                        <a href="mailto:post@allegro.no">post@allegro.no</a>
                        </p>
                    </div>
                    <div className="footer__grid__column">

                    <p className="text-center">
                        <a className="logo" href="allegro.no" target="_blank">
                            <img className="allegro-logo" src="/assets/img/allegro.svg" alt="Allegro"/>
                        </a>
                    </p>

                    <p className="text-center"><a href="http://email.allegro.no/h/r/0A947A75B22B3323" target="_blank" rel="noreferrer">Meld deg på vårt nyhetsbrev</a></p>

                    </div>
                    <div className="footer__grid__column footer__grid__column--facebook">
                        <p>
                            <a href="https://www.facebook.com/Allegrogruppen" className="facebook"><i className="fa fa-facebook-official fa-2x"></i> </a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>


    </React.Fragment>
)

export default IndexPage
